import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import SideNavBar from "./components/SideNavBar";
import AllProducts from "./pages/AllProducts";
import AddProduct from "./pages/AddProduct";

import AllCategories from "./pages/Categories/AllCategories";
import AllSubCategories from "./pages/Categories/AllSubCategories";
import AddCategory from "./pages/Categories/AddCategory";
import AddSpecialCategory from "./pages/Categories/AddSpecialCategory";
import AddSubCategory from "./pages/Categories/AddSubCategory";
import AllSpecialCategories from "./pages/Categories/AllSpecialCategories";
import Customers from "./pages/Customers/Customers";
import VariableProduct from "./pages/Products/VariableProduct";
import AllOrders from "./pages/Orders/AllOrders";
import Size from "./pages/Variations/Size";
import ExcelUpload from "./pages/Products/ExcelUpload";
import Brand from "./pages/Variations/Brand";
import Color from "./pages/Variations/Color";
import Flavour from "./pages/Variations/Flavour";
import Supplier from "./pages/Variations/Supplier";
import Weight from "./pages/Variations/Weight";
import UpdateProduct from "./pages/Products/UpdateProduct";
import ListSize from "./pages/Variations/List/ListSize";
import ListBrand from "./pages/Variations/List/ListBrand";
import ListColor from "./pages/Variations/List/ListColor";
import ListFlavour from "./pages/Variations/List/ListFlavour";
import ListWeight from "./pages/Variations/List/ListWeight";
import ListSupplier from "./pages/Variations/List/ListSupplier";
import UpdateOrder from "./pages/Orders/UpdateOrder";
import Sale from "./pages/Sale";
import OrderDetail from "./pages/Orders/OrderDetail";

import ImageBanner from "./pages/Banners/ImageBanner";
import ListImgBanner from "./pages/Banners/ListImgBanner";
import AllVideos from "./pages/Banners/AllVideos";
import AddVideoBanner from "./pages/Banners/AddVideoBanner";
import AllAqua from "./pages/AllAqua";
import AddCustomProduct from "./pages/Products/AddCustomProduct";
import AllCustom from "./pages/AllCustom";
import CreateDelivery from "./pages/Orders/CreateDelivery";
import AllDelivery from "./pages/Orders/AllDelivery";
import AllEmployees from "./pages/AllEmployees";
import DeliveryByEmployee from "./pages/Orders/DeliveryByEmployee";
import Dashboard from "./pages/Dashboard/Dashboard";
import useAuth from "./auth/useAuth";
import Login from "./pages/Login";
import Register from "./pages/Register";
import AllReview from "./pages/AllReview";
import ProductReview from "./pages/ProductReview";
import Revenue from "./pages/Dashboard/Revenue";
import EditCategory from "./pages/Categories/EditCategory";
import EditSubCategory from "./pages/Categories/EditSubCategory";
import EditSpecialCategory from "./pages/Categories/EditSpecialCategory";
import SearchProducts from "./pages/SearchProducts";
import OrderFilter from "./pages/Orders/OrderFilter";
import FailedOnline from "./pages/Orders/FailedOnline";
import AllTextBanners from "./pages/Banners/AllTextBanners";
import AddTextBanner from "./pages/Banners/AddTextBanner";
import UpdateTextBanner from "./pages/Banners/UpdateTextBanner";

function HeaderComponent() {
  const { user } = useAuth();
  if (!user) {
    return <Login />;
  }

  return (
    <>
      <Header />
      <div id="layoutSidenav">
        <SideNavBar />
        <Routes>
          <Route path="/product/list" element={<AllProducts />}></Route>
          <Route path="/product/search" element={<SearchProducts />}></Route>
          <Route path="/category/edit" element={<EditCategory />}></Route>
          <Route
            path="/category/editspecialcategory"
            element={<EditSpecialCategory />}
          ></Route>
          <Route
            path="/category/editsubcategory"
            element={<EditSubCategory />}
          ></Route>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/reviews" element={<AllReview />}></Route>
          <Route path="/revenue" element={<Revenue />}></Route>
          <Route path="/productreview" element={<ProductReview />}></Route>
          <Route path="/employee/list" element={<AllEmployees />}></Route>
          <Route path="/product/varlist" element={<AllAqua />} />
          <Route path="/product/customlist" element={<AllCustom />} />
          <Route path="/product/custom" element={<AddCustomProduct />} />
          <Route path="/product/excel" element={<ExcelUpload />}></Route>
          <Route path="/product/create" element={<AddProduct />}></Route>
          <Route path="/product/update" element={<UpdateProduct />}></Route>
          <Route
            path="/product/create-var"
            element={<VariableProduct />}
          ></Route>
          <Route path="/category/create-category" element={<AddCategory />} />
          <Route path="/category/sub-create" element={<AddSubCategory />} />
          <Route path="/category/list" element={<AllCategories />} />
          <Route path="/category/sublist" element={<AllSubCategories />} />
          <Route
            path="/category/speciallist"
            element={<AllSpecialCategories />}
          />
          <Route
            path="/category/special-category"
            element={<AddSpecialCategory />}
          />
          <Route path="/banners/text" element={<AllTextBanners />} />
          <Route path="/banners/addtextbanner" element={<AddTextBanner />} />
          <Route
            path="/banners/updatetextbanner"
            element={<UpdateTextBanner />} />
          <Route path="/customer/list" element={<Customers />} />
          <Route path="/orders/list" element={<AllOrders />} />
          <Route path="/orders/order-filter" element={<OrderFilter />} />
          <Route path="/orders/failed-online" element={<FailedOnline />} />
          <Route path="/orders/update" element={<UpdateOrder />} />
          <Route path="/variations/size" element={<Size />} />
          <Route path="/list-variations/size" element={<ListSize />} />
          <Route path="/variations/brand" element={<Brand />} />
          <Route path="/list-variations/brand" element={<ListBrand />} />
          <Route path="/variations/color" element={<Color />} />
          <Route path="/list-variations/color" element={<ListColor />} />
          <Route path="/variations/supplier" element={<Supplier />} />
          <Route path="/list-variations/supplier" element={<ListSupplier />} />
          <Route path="/variations/weight" element={<Weight />} />
          <Route path="/list-variations/weight" element={<ListWeight />} />
          <Route path="/variations/flavour" element={<Flavour />} />
          <Route path="/list-variations/flavour" element={<ListFlavour />} />
          <Route path="/category/sale" element={<Sale />} />
          <Route path="/orders/detail" element={<OrderDetail />} />
          <Route path="/banners/videos" element={<AllVideos />} />
          <Route path="/banners/addVideoBanner" element={<AddVideoBanner />} />
          <Route path="/banners/image" element={<ListImgBanner />} />
          <Route path="/banners/addImgBanner" element={<ImageBanner />} />
          <Route path="/orders/delivery" element={<CreateDelivery />} />
          <Route path="/orders/list-delivery" element={<AllDelivery />} />
          {/* <Route path="/register" element={<Register />} /> */}
          <Route
            path="/orders/deliveryByEmployee"
            element={<DeliveryByEmployee />}
          />
        </Routes>
      </div>
    </>
  );
}

export default HeaderComponent;
