import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import apiClient from "../../api/client";

const AllVideos = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
  }, [del]);

  const fetchProducts = async () => {
    setIsLoaded(true);
    const { data } = await apiClient.get("/variations/videos-banner");
    setGetList(data);
    setIsLoaded(false);
  };

  const handlDeleteById = async (id) => {
    const result = await apiClient.delete("/variations/deletevideobanner", {
      id,
    });
    if (result.ok) {
      swal("Delete Success");
      setDel(!del);
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Videos</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">Banners</li>
          </ol>
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Banners *(Total {getList.length} )</h4>
                <Button
                  style={{ marginLeft: "220px" }}
                  variant="contained"
                  color="success"
                  onClick={(e) => navigate("/banners/addVideoBanner")}
                >
                  <AddIcon /> Add New
                </Button>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 60 }}>No.</th>
                        <th style={{ width: 400 }}>Link</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && getList.length > 0
                        ? getList?.map((row, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.videoLink}</td>

                                <td className="action-btns">
                                  <Typography
                                    className="delete-btn"
                                    onClick={(e) => handlDeleteById(row._id)}
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </Typography>
                                </td>
                              </tr>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AllVideos;
