import React, { useEffect, useState } from "react";
import { Button, MenuItem, Select } from "@material-ui/core";
import swal from "sweetalert";

import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const AddSubCategory = () => {
  const [id, setId] = useState();
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState("1");
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [photo, setPhoto] = useState();
  const [modal, setModal] = useState(false);
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setCat(data);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New SubCategory",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        setModal(true);
        const formData = new FormData();

        formData.append("image", photo, photo.name);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const { data } = await apiClient.post(
          "/uploadSingleImage/uploadSingleImage",
          formData,
          config
        );

        const result = await apiClient.post("/variations/subcategory", {
          id,
          name,
          description,
          photoLink: data,
          catId,
        });

        if (result.ok) {
          setModal(false);
          setId("");
          setName("");
          setDescription("");
          setPhoto("");
          setCatId("");
          swal("Sucess");
          navigate("/category/sublist");
        } else {
          setModal(false);
          swal("Error Retry!");
        }
      }
    });
  };
  const navigate = useNavigate();
  const uploadFileHandler = async (e) => {
    setPhoto(e.target.files[0]);
  };
  return (
    <div id="layoutSidenav_content">
      {modal ? (
        <div
          style={{ textAlign: "center", marginTop: "250px", fontSize: "25px" }}
        >
          Loading... Please wait!
        </div>
      ) : (
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-md-9 col-lg-6">
                <h2 className="mt-30 page-title">Category</h2>
              </div>
              <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
                <Button
                  variant="contained"
                  onClick={(e) => navigate("/category/list")}
                >
                  <i class="fas fa-arrow-left" /> Back
                </Button>
              </div>
            </div>
            <ol className="breadcrumb mb-30">
              <li className="breadcrumb-item">
                <a href="/">Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/admin/product/create">Category</a>
              </li>
              <li className="breadcrumb-item active">Add Sub-Category</li>
            </ol>

            {/* Add category  */}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-title-2">
                    <h4>Add New Sub-Category</h4>
                  </div>
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            SubCategory Name*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=" Name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-2">
                        <div className="form-group">
                          <label className="form-label">SubCategory Id</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Id Number"
                            name="id"
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Parent-Category</label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={catId}
                            label="Category"
                            onChange={(e) => setCatId(e.target.value)}
                          >
                            {cat?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Select Image </label>
                          <form>
                            <input type="file" onChange={uploadFileHandler} />
                          </form>
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-6 col-md-2 ">
                        <div className="form-group">
                          <label className="form-label">
                            SubCategory Description*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=" Description"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Add New
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default AddSubCategory;
