import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";

import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const AddCustomProduct = () => {
  const [id, setId] = useState();
  const [name, setName] = useState();
  const [thickness, setThickness] = useState();
  const [pup, setPup] = useState();
  const [minl, setMinl] = useState();
  const [maxl, setMaxl] = useState();

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post("/aquarium/create-custom-product", {
          sku: id,
          name,
          thickness,
          per_unit_price: pup,
          min_length_allowed: minl,
          max_length_allowed: maxl,
        });
        if (result.ok) {
          setId("");
          setName("");
          setThickness("");
          setPup("");
          setMinl("");
          setMaxl("");
          swal("Sucess");
          navigate("/product/customlist");
        } else {
          swal("Error Retry!");
        }
      }
    });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Product</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/category/list")}
              >
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Product</a>
            </li>
            <li className="breadcrumb-item active">Add Custom</li>
          </ol>

          {/* Add category  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Product</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Name*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Name"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">SKU*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Sku"
                          name="id"
                          value={id}
                          onChange={(e) => setId(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Thickness(mm)*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product thickness"
                          name="thickness"
                          value={thickness}
                          onChange={(e) => setThickness(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Per unit price*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Price"
                          name="pup"
                          value={pup}
                          onChange={(e) => setPup(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">
                          Minimum Length(cm)*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Min Length"
                          name="minl"
                          value={minl}
                          onChange={(e) => setMinl(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">
                          Maximum Length(cm)*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Max Length"
                          name="maxl"
                          value={maxl}
                          onChange={(e) => setMaxl(e.target.value)}
                        />
                      </div>
                    </div>
                    <br />

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add New
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddCustomProduct;
