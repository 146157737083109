import React from "react";
import ReactApexChart from "react-apexcharts";

function Chart({ paymentHistory }) {
  let paymentDates = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  // for (let i = 0; i < paymentHistory.length; i++) {
  //   const newDate = new Date(paymentHistory[i].createdAt).toLocaleDateString();

  //   let localDate = newDate;

  //   paymentDates = [...paymentDates, localDate];
  // }

  let paymentReceived = [];
  for (let i = 0; i < paymentHistory.length; i++) {
    paymentReceived = [
      ...paymentReceived,
      [new Date(paymentHistory[i].createdAt), paymentHistory[i].totalPrice.toFixed(2)]
    ];
  }
  // for (let i = 0; i < paymentHistory.length; i++) {
  //  const a = new Date(paymentHistory[i].createdAt).getMonth()
  //  console.log(a);
  // }

  const series = [
    {
      name: "orders received",
      data: paymentReceived,
    },
  ];
 
  // const options = {
  //   chart: {
  //     zoom: { enabled: false },
  //     toolbar: { show: false },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },

  //   stroke: {
  //     curve: "smooth",
  //   },
  //   xaxis: {
  //     type: "datetime",
  //     categories: paymentDates,
  //   },
  //   tooltip: {
  //     x: {
  //       format: "dd/MM/yy",
  //     },
  //   },
  // };
  const options= {
    chart: {
      id: 'area-datetime',
      type: 'line',
      height: 350,
      zoom: {
        autoScaleYaxis: true
      }
    },
    annotations: {
      yaxis: [{
        y: 500,
        borderColor: '#999',
        label: {
          show: true,
          text: 'Support',
          style: {
            color: "#fff",
            background: '#00E396'
          }
        }
      }],
      xaxis: [{
        x: new Date().getTime(),
        borderColor: '#999',
        yAxisIndex: 0,
        label: {
          show: true,
          text: 'Rally',
          style: {
            color: "#fff",
            background: '#775DD0'
          }
        }
      }]
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      style: 'hollow',
    },
    xaxis: {
      type: 'datetime',
      min: new Date('01 Oct 2022').getTime(),
      tickAmount: 6,
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100]
      }
    },
  }




  return (
    <div
      style={{
        backgroundColor: "white",
        textAlign: "center",
        width: "90%",
        margin: "10px auto",
        padding: "10px",
      }}
    >
      <br />
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
}

export default Chart;
