import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "./../../api/client";
import { useLocation, useNavigate } from "react-router-dom";

const UpdateProduct = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location.state;
  console.log(data)
  const [state, setState] = useState({
    name: data?.name,
    sku: data?._id,
    groupId: data?.groupId,
    image: data?.image,
    color: data?.color?._id ? data?.color?._id : data?.color,
    flavour: data?.flavour?._id ? data?.flavour?._id : data?.flavour,
    brand: data?.brand?._id ? data?.brand?._id : data?.brand,
    category: data?.category?._id ? data?.category?._id : data?.category,
    subcategory: data?.subcategory?._id ? data?.subcategory?._id : data?.subcategory,
    specialcategory: data?.specialcategory?._id ? data?.specialcategory?._id : data?.specialcategory,
    supplier: data?.supplier,
    size: data?.size?._id,
    weight: data?.weight,
    description: data?.description,
    details: data?.details,
    cost_price: data?.cost_price,
    sell_price: data?.sell_price,
    discount: data?.discount,
    countInStock: data?.countInStock,
    notes: data?.notes,
  });
  
  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Update the product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post("/products/update", {
          name: state.name,
          sku: state.sku,
          groupId: state.groupId,
          image: state.image,
          color: state.color,
          flavour: state.flavour,
          brand: state.brand,
          category: state.category,
          subcategory: state.subcategory,
          specialcategory: state.specialcategory,
          supplier: state.supplier,
          size: state.size,
          weight: state.weight,
          description: state.description,
          details: state.details,
          cost_price: state.cost_price,
          sell_price: state.sell_price,
          discount: state.discount,
          countInStock: state.countInStock,
          notes: state.notes,
        });
        if (result.ok) {
          swal("Updated Product Successfully");
          navigate("/product/list");
        }
      }
    });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Products</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/product/list">Products</a>
            </li>
            <li className="breadcrumb-item active">Update Product</li>
          </ol>

          {/* Add prd  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Product</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Name*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product SKU*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product SKU"
                            name="sku"
                            value={state.sku}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Group Id*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Group Id"
                            name="groupId"
                            value={state.groupId}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">No. of Images*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="image"
                            value={state.image}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Color*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Color"
                            name="color"
                            value={state.color}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Flavour*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Flavour"
                            name="flavour"
                            value={state.flavour}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Brand*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Brand"
                            name="brand"
                            value={state.brand}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Category*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Category"
                            name="category"
                            value={state.category}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Sub-Category*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Sub-Category"
                            name="subcategory"
                            value={state.subcategory}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Special-Category*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Special-Category"
                            name="specialcategory"
                            value={state.specialcategory}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Supplier*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Supplier"
                            name="supplier"
                            value={state.supplier}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Size*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Size"
                            name="size"
                            value={state.size}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Description*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Description"
                          name="description"
                          value={state.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Details*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Details"
                          name="details"
                          value={state.details}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Cost Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product C.P"
                            name="cost_price"
                            value={state.cost_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Sell Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product S.P"
                            name="sell_price"
                            value={state.sell_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Weight*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Weight"
                            name="weight"
                            value={state.weight}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Discount*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Disount"
                            name="discount"
                            value={state.discount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Count In Stock*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Stock"
                            name="countInStock"
                            value={state.countInStock}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Notes*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          name="notes"
                          value={state.notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UpdateProduct;
