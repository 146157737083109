import React, { useState } from "react";
import { Button } from "@material-ui/core";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";

const AddVideoBanner = () => {
  const [video, setVideo] = useState();

  const [uploadVisible, setUploadVisible] = useState(false);

  const uploadVideoHandler = async (e) => {
    setVideo(e.target.files[0]);
  };

  const navigate = useNavigate();

  const handlesubmitVideo = async () => {
    setUploadVisible(true);
    if (video) {
      const formData = new FormData();

      formData.append("video", video, video.name);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await apiClient.post("/uploadVideo", formData, config);

      if (data) {
        const result = await apiClient.post("/variations/create-video-banner", {
          videoLink: data,
        });
        setUploadVisible(false);
        swal("Successfully Uploaded Video");
        setVideo("");
        navigate("/banners/videos");
      }
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        {uploadVisible ? (
          <h2 style={{ textAlign: "center", marginTop: "200px" }}>
            Uploading Please Wait...
          </h2>
        ) : (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-md-9 col-lg-6">
                <h2 className="mt-30 page-title">Size</h2>
              </div>
              <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
                <Button
                  variant="contained"
                  onClick={(e) => navigate("/banners/videos")}
                >
                  <i class="fas fa-arrow-left" /> Back
                </Button>
              </div>
            </div>
            <ol className="breadcrumb mb-30">
              <li className="breadcrumb-item">
                <a href="/">Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/admin/product/create">Products</a>
              </li>
              <li className="breadcrumb-item active">Upload </li>
            </ol>

            {/* Add category  */}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Select Video for Banner{" "}
                          </label>
                          <form>
                            <input type="file" onChange={uploadVideoHandler} />
                          </form>
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handlesubmitVideo}
                      >
                        Upload
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default AddVideoBanner;
