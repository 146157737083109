import React, { useState, useEffect } from "react";
import { Button, MenuItem, Select } from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "./../../api/client";
import { useNavigate } from "react-router-dom";

const VariableProduct = () => {
  const [file2, setFile2] = useState([]);
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState(cat ? cat[0]?._id : "1");
  const [subcat, setSubCat] = useState();
  const [subId, setSubId] = useState(subcat ? subcat[0]?._id : "1");
  const [specialcat, setSpecialCat] = useState();
  const [specialId, setSpecialId] = useState(
    specialcat ? specialcat[0]?._id : "5"
  );

  const navigate = useNavigate();

  const [brands, setBrands] = useState();
  const [brandId, setBrandId] = useState("1");

  const [state, setState] = useState({
    name: "",
    sku: "",
    groupId: "",
    image: "",
    thickness: "",
    length: "",
    width: "",
    depth: "",
    supplier: "",
    size: "",
    weight: "",
    description: "",
    details: "",
    cost_price: "",
    sell_price: "",
    discount: "",
    countInStock: "",
    notes: "",
  });

  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
    fetchSpecialCategories();
    fetchBrands();
  }, []);

  const fetchBrands = async () => {
    const { data } = await apiClient.get("/variations/getbrand");
    setBrands(data);
  };

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setCat(data);
  };

  const fetchSubCategories = async () => {
    const { data } = await apiClient.get("/variations/get-sub-categories");
    setSubCat(data);
  };

  const fetchSpecialCategories = async () => {
    const { data } = await apiClient.get("/variations/get-special-categories");

    setSpecialCat(data);
  };

  const uploadFileHandler = async (e) => {
    setFile2([...file2, ...e.target.files]);
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to Add New Product",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        const result = await apiClient.post(
          "/aquarium/create-aquarium-product",
          {
            name: state.name,
            sku: state.sku,
            groupId: state.groupId,
            image: state.image,
            thickness: state.thickness,
            length: state.length,
            width: state.width,
            depth: state.depth,
            brand: brandId,
            category: catId,
            subcategory: subId,
            specialcategory: specialId,
            supplier: state.supplier,
            description: state.description,
            details: state.details,
            cost_price: state.cost_price,
            sell_price: state.sell_price,
            discount: state.discount,
            countInStock: state.countInStock,
            notes: state.notes,
          }
        );
        if (result.ok) {
          setState({
            name: "",
            sku: "",
            groupId: "",
            image: "",
            supplier: "",
            weight: "",
            description: "",
            details: "",
            cost_price: "",
            sell_price: "",
            discount: "",
            countInStock: "",
            notes: "",
          });
          swal("Success product added");
          navigate("/product/varlist");
        }
      }
    });
  };

  const handlesubmit2 = async () => {
    if (file2) {
      const formData = new FormData();

      for (const file of file2) {
        formData.append("image", file, file.name);
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const result = await apiClient.post(
        "/uploadImages/uploadImages",
        formData,
        config
      );
      if (result.ok) {
        swal("Images Uploaded Successfully");
      } else {
        swal("Erro Retry!");
      }
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Products</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => this.handleBack()}>
                <i class="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Products</a>
            </li>
            <li className="breadcrumb-item active">Add Variable Product</li>
          </ol>

          {/* Add prd  */}
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Product</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Name*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Name"
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product SKU*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product SKU"
                            name="sku"
                            value={state.sku}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Group Id*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Group Id"
                            name="groupId"
                            value={state.groupId}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">No. of Images*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="image"
                            value={state.image}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Thickness(mm)*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="thickness"
                            value={state.thickness}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Length(cm)*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="length"
                            value={state.length}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Width(cm)*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="width"
                            value={state.width}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Depth(cm)*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="depth"
                            value={state.depth}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Product Brand*</label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={brandId}
                            label="Brand"
                            onChange={(e) => setBrandId(e.target.value)}
                          >
                            {brands?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Category*
                          </label>

                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={catId}
                            label="Category"
                            onChange={(e) => setCatId(e.target.value)}
                          >
                            {cat?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Sub-Category*
                          </label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={subId}
                            label="Sub-Category"
                            onChange={(e) => setSubId(e.target.value)}
                          >
                            {subcat?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Product Special-Category*
                          </label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={specialId}
                            label="Special-Category"
                            onChange={(e) => setSpecialId(e.target.value)}
                          >
                            {specialcat?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Supplier*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Supplier Name"
                            name="supplier"
                            value={state.supplier}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Description*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Description"
                          name="description"
                          value={state.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Details*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Product Details"
                          name="details"
                          value={state.details}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Cost Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product C.P"
                            name="cost_price"
                            value={state.cost_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Sell Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product S.P"
                            name="sell_price"
                            value={state.sell_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Discount*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Disount"
                            name="discount"
                            value={state.discount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Count In Stock*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Product Stock"
                            name="countInStock"
                            value={state.countInStock}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Notes*</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Notes"
                          name="notes"
                          value={state.notes}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Add Product
                    </button>
                  </div>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="col-lg-3 col-md-2">
                      <div className="form-group">
                        <label className="form-label">Select Images </label>
                        <form>
                          <input
                            type="file"
                            multiple
                            onChange={uploadFileHandler}
                          />
                        </form>
                      </div>
                    </div>

                    <button
                      className="save-btn hover-btn"
                      type="submit"
                      onClick={handlesubmit2}
                    >
                      Upload Images
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default VariableProduct;
