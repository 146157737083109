import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import { saveAs } from "file-saver";
import apiClient from "../../api/client";
import axios from "axios";

const OrderDetail = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const order = location.state;

  const date = new Date(order.updatedAt).toDateString();
  const time = new Date(order.updatedAt).toLocaleTimeString();

  const createAndDownloadPdf = async () => {
    await axios
      .post(`https://backend.hkmheavyequipments.qa/api/create-pdf`, {
        name: order.user.name,
        id: order._id,
        address: order.shippingAddress,

        deliverySlot: order.deliverySlot,
        PaymentMode: order.paymentMethod,
        shippingCharges: order.shippingPrice,
        total: order.totalPrice,

        items: order.orderItems,
      })
      .then(() =>
        axios.get(`https://backend.hkmheavyequipments.qa/api/fetch-pdf`, {
          responseType: "blob",
        })
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });

        saveAs(pdfBlob, "invoice.pdf");
      });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="order">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Orders</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={(e) => navigate("/orders/list")}
              >
                <i className="fas fa-arorder-left" /> Back
              </Button>
              <Button variant="contained" onClick={createAndDownloadPdf}>
                <i className="fas fa-arorder-left" /> Download
              </Button>
            </div>
          </div>

          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item ">Orders</li>
            <li className="breadcrumb-item active">Detail</li>
          </ol>
          <div className="pdf-print">
            <div className="col-lg-12 col-md-12">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="card card-static-2 col-lg-3">
                  <div className="card-title-2">
                    <h4>Customer</h4>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <p>{order.user.name}</p>
                    <p>{order.shippingAddress.email}</p>
                    <p>{order.shippingAddress.mobileNumber}</p>
                  </div>
                </div>
                <div className="card card-static-2 col-lg-3">
                  <div className="card-title-2">
                    <h4>Order Info</h4>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <p>Payment Method: {order?.paymentMethod}</p>
                    <p>Status: {order?.deliveryStatus}</p>
                    <p>Payment Status: {(order?.paymentMethod == "COD" ? (order?.deliveryStatus !== "Cancelled" ? "COD" : "Cancelled") : (order?.isPaid == true ? "Success" : "Failed"))}</p>
                  </div>
                </div>
                <div className="card card-static-2 col-lg-3">
                  <div className="card-title-2">
                    <h4>Deliver To</h4>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <p>Address: {order.shippingAddress.address}, </p>
                    <p>City: {order.shippingAddress.city},</p>
                    <p>Postal Code {order.shippingAddress.postalCode},</p>
                  </div>
                </div>
              </div>

              <div className="card card-static-2 mt-30 mb-30">
                <div className="card-body-table">
                  <div className="table-responsive">
                    <table className="table ucp-table table-hover">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Unit Price</th>
                          <th>Quantity</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order?.orderItems?.map((row, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  width: "520px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div className="cate-img-5">
                                  <img src={row.image} alt="product-name" />
                                </div>
                                {row.name}
                              </td>
                              <td>{row.price} QAR</td>
                              <td>{row.qty}</td>
                              <td>{row.qty * row.price} QAR</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default OrderDetail;
