import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiClient from "./../api/client";
import { Button, Typography, MenuItem, Select } from "@material-ui/core";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import swal from "sweetalert";
import { Search } from "@mui/icons-material";

const AllProducts = () => {
  const [isloaded, setIsLoaded] = useState(false);
  const [del, setDel] = useState(false);
  const [getList, setGetList] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [search, setSearch] = useState();
  const navigate = useNavigate();

  const [brands, setBrands] = useState();
  // const [brandId, setBrandId] = useState("1");
  const [brandId, setBrandId] = useState();

  const [cat, setCat] = useState();
  // const [catId, setCatId] = useState(cat ? cat[0]?._id : "1");
  const [catId, setCatId] = useState();
  const [outOfStock, setOutOfStock] = useState(false);

  useEffect(() => {
  
    fetchProducts();
    fetchBrands();
    fetchCategories();
  }, [currentPageNo, del, outOfStock]);

  const handleOutofStock = async () => {
    const { data } = await apiClient.get("/products/outofstockproducts", {
      pageNumber: currentPageNo,
    });
  
    setPageCount(data.pageCount);
    setGetList(data.products);
    setIsLoaded(false);
  };

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setCat(data);
  };

  const fetchBrands = async () => {
    const { data } = await apiClient.get("/variations/getbrand");
    setBrands(data);
  };

  const fetchProducts = async () => {
    console.log(outOfStock)
    
 if (catId || brandId) {
  setIsLoaded(true);
  const { data } = await apiClient.get("/products", {
    pageNumber: currentPageNo,
    category: catId,
    brand: brandId,
  });
  setPageCount(data.pageCount);
  setGetList(data.products);
  setIsLoaded(false);
 } else if ( outOfStock === true){
 
  setIsLoaded(true);
  const { data } = await apiClient.get("/products/outofstockproducts", {
    pageNumber: currentPageNo,
  });
  setPageCount(data.pageCount);
  setGetList(data.products);
  setIsLoaded(false);
 } else
    { setIsLoaded(true);
      const { data } = await apiClient.get("/products", {
        pageNumber: currentPageNo,
      });
      setPageCount(data.pageCount);
      setGetList(data.products);
      setIsLoaded(false);}
  };

  const handleSubmit = async () => {
    
    setIsLoaded(true);
    const { data } = await apiClient.get("/products", {
      pageNumber: currentPageNo,
      category: catId,
      brand: brandId,
    });
    setPageCount(data.pageCount);
    setGetList(data.products);
    setIsLoaded(false);
  };

  const handlDeleteById = async (id) => {
    const result = await apiClient.delete("/products/delete", { id });
    if (result.ok) {
      swal("Delete Success");
      setDel(!del);
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Products</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={(e) => navigate("/")}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="index.html">Dashboard</a>
            </li>
            <li className="breadcrumb-item active">{outOfStock == true ? "Out of Stock Products" : "All Products"}</li>
          </ol>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <button
                onClick={() => {
                  setOutOfStock(true);
                  handleOutofStock()}}
                style={{
                  border: "none",
                  borderRadius: "10px",
                  padding: "10px",
                  backgroundColor: "orange",
                }}
              >
                Out of Stock
              </button>
              
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-2">
              <div className="form-group">
                <label className="form-label">Product Brand*</label>
                <Select
                  className="form-control"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={brandId}
                  label="Brand"
                  onChange={(e) => setBrandId(e.target.value)}
                >
                  {brands?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </div>
            </div>
            <div className="col-lg-2 col-md-2">
              <div className="form-group">
                <label className="form-label">Product Category*</label>

                <Select
                  className="form-control"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={catId}
                  label="Category"
                  onChange={(e) => setCatId(e.target.value)}
                >
                  {cat?.map((item, index) => {
                    return <MenuItem value={item._id}>{item.name}</MenuItem>;
                  })}
                </Select>
              </div>
            </div>
            <button
              className="save-btn hover-btn"
              type="submit"
              onClick={handleSubmit}
            >
              Filter
            </button>
            <button
              className="save-btn hover-btn"
              type="submit"
              onClick={() => {
                setBrandId()
                setCatId()
                setOutOfStock(false)
                window.location.reload()
              }}
            >
              Clear Filter
            </button>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mt-30 mb-30">
              <div className="card-title-2">
                <h4>All Products</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: 60 }}>Id</th>
                        <th style={{ width: 100 }}>Image</th>
                        <th>Name</th>
                        <th>Group Id</th>
                        <th>Category</th>
                        <th>Sub-Category</th>
                        <th>Special-Category</th>
                        <th>Brand</th>
                        <th>Count in stock</th>
                        <th>Size</th>
                        <th>Cost-Price</th>
                        <th>Seller-Price</th>
                        <th>Action</th>
                        <th>Add Sale/Discount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isloaded !== true && getList.length > 0
                        ? getList?.map((row, index) => {
                            const imgsrc = `https://fiora-pets-s3.s3.amazonaws.com/${row?._id}.jpg`;

                            return (
                              <tr key={index}>
                                <td>{row._id}</td>
                                <td>
                                  <div className="cate-img-5">
                                    <img src={imgsrc} alt="product-name" />
                                  </div>
                                </td>
                                <td>{row?.name}</td>

                                <td>{row?.groupId}</td>
                                <td>{row?.category?.name}</td>
                                <td>{row?.subcategory?.name}</td>
                                <td>{row?.specialcategory?.name}</td>
                                <td>{row?.brand?.name}</td>
                                <td>{row?.countInStock}</td>
                                <td>{row?.size?.name}</td>

                                <td>{row.cost_price} QAR</td>
                                <td>{row.sell_price} QAR</td>

                                <td className="action-btns">
                                  <Link to="/product/update" state={row}>
                                    <Typography className="edit-btn">
                                      <i className="fas fa-edit" />
                                    </Typography>
                                  </Link>
                                  <Typography
                                    className="delete-btn"
                                    onClick={(e) => handlDeleteById(row._id)}
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </Typography>
                                </td>
                                <td>
                                  <Link
                                    to="/category/sale"
                                    state={{ row, catname: "product" }}
                                  >
                                    <Typography className="edit-btn">
                                      <i className="fas fa-edit" />
                                    </Typography>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })
                        : "Loading..."}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            count={pageCount}
            color="primary"
            onChange={(e, value) => setCurrentPageNo(value)}
          />
        </div>
      </main>
    </div>
  );
};

export default AllProducts;
