import { BrowserRouter as Router } from "react-router-dom";

import { useEffect, useState } from "react";

import AuthContext from "./auth/context";
import jwtDecode from "jwt-decode";
import HeaderComponent from "./HeaderComponent";

function App() {
  const [user, setUser] = useState();

  const restoreUser = async () => {
    const user = await sessionStorage.getItem("token");
    if (user) setUser(jwtDecode(user));
  };

  useEffect(() => {
    restoreUser();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <Router>
        <HeaderComponent />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
