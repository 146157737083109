import React, { useEffect, useState } from "react";
import { Button, MenuItem, Select } from "@material-ui/core";
import swal from "sweetalert";

import { useNavigate } from "react-router-dom";
import apiClient from "./../../api/client";
import { useLocation } from "react-router";

const EditSubCategory = () => {
  const location = useLocation();
  const data = location.state;

  const [id, setId] = useState(data?._id);
  const [name, setName] = useState(data?.name);
  const [description, setDescription] = useState(data?.description);
  const [photo, setPhoto] = useState();
  const [modal, setModal] = useState(false);
  const [catId, setCatId] = useState(data?.category);
  const [categories, setCat] = useState();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/variations/get-categories");
    setCat(data);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    swal({
      title: "Are you sure?",
      text: "You want to update Category",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (success) => {
      if (success) {
        setModal(true);
        if (photo) {
          const formData = new FormData();

          formData.append("image", photo, photo.name);

          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };

          const { data } = await apiClient.post(
            "/uploadSingleImage/uploadSingleImage",
            formData,
            config
          );

          const d1 = data;

          createCategory({ d1 });
        } else {
          createCategory({ d1: "" });
        }
      }
    });
  };

  const createCategory = async ({ d1 }) => {
    const result = await apiClient.post("/variations/updatesubcategory", {
      id,
      name,
      description,
      photoLink: d1,
      catId,
    });
    if (result.ok) {
      setModal(false);
      setId("");
      setName("");
      setDescription("");
      setPhoto("");
      setCatId("");
      swal("Sucess");
      navigate("/category/sublist");
    } else {
      setModal(false);
      swal("Error Retry!");
    }
  };

  const navigate = useNavigate();

  const uploadFileHandler = async (e) => {
    setPhoto(e.target.files[0]);
  };

  return (
    <div id="layoutSidenav_content">
      {modal ? (
        <div
          style={{ textAlign: "center", marginTop: "250px", fontSize: "25px" }}
        >
          Loading... Please wait!
        </div>
      ) : (
        <main>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-5 col-md-9 col-lg-6">
                <h2 className="mt-30 page-title">Sub-Category</h2>
              </div>
              <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
                <Button
                  variant="contained"
                  onClick={(e) => navigate("/category/list")}
                >
                  <i class="fas fa-arrow-left" /> Back
                </Button>
              </div>
            </div>
            <ol className="breadcrumb mb-30">
              <li className="breadcrumb-item">
                <a href="/">Dashboard</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/admin/product/create">Sub-Category</a>
              </li>
              <li className="breadcrumb-item active">Update Sub-Category</li>
            </ol>

            {/* Add category  */}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-title-2">
                    <h4>Update Sub-Category</h4>
                  </div>
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Sub-Category Name*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Category Name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Parent-Category</label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={catId}
                            label="Category"
                            onChange={(e) => setCatId(e.target.value)}
                          >
                            {categories?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <br />
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Select Image for Sub-Category{" "}
                          </label>
                          <form>
                            <input
                              type="file"
                              // multiple
                              onChange={uploadFileHandler}
                            />
                          </form>
                        </div>
                      </div>

                      <br />
                      <div className="col-lg-6 col-md-2 ">
                        <div className="form-group">
                          <label className="form-label">
                            Sub-Category Description*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Category Description"
                            name="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>
                      </div>

                      <button
                        className="save-btn hover-btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

export default EditSubCategory;
